import Button from "../../../components/button/Button";
import ArrowSmall from "../../../components/svg/ArrowSmall";
import { useDispatch, useSelector } from "react-redux";
import { selectActiveReservation } from "../../../features/reservation/reservationSlice";
import {
	clearLoadingOverlay,
	setLoadingOverlay,
} from "../../../features/application/appSlice";
import { useEffect, useState } from "react";
import { post } from "../../../helpers/api";
import { showSnackbar } from "../../../helpers/notification";
import { resetReservations } from "../../../helpers/reservation";

export default function ConfirmationCheckOut() {
	const activeReservation = useSelector(selectActiveReservation);

	return (
		<div className="flex justify-center items-center flex-col h-screen w-full">
			<div className="flex justify-center items-center flex-col w-full">

				{activeReservation && activeReservation?.Customers.length === 1 && (
					<div className="text-h1 text-center">
						{activeReservation?.Customers[0].FirstName}, you're checked out now.
					</div>
				)}

				{activeReservation && activeReservation?.Customers.length >= 2 && (
					<div className="text-h1 text-center">
						{activeReservation?.Customers.map((customer, i) => {
							if (i === 0) {
								return <span key={i}>{customer.FirstName}</span>;
							} else if (i === activeReservation?.Customers.length - 1) {
								return <span key={i}> and {customer.FirstName}</span>;
							} else {
								return <span key={i}>, {customer.FirstName}</span>;
							}
						})}
						, you're{" "}
						{activeReservation?.Customers.length > 2 ? "(all)" : "(both)"}{" "}
						checked out now.
					</div>
				)}

				<div className="mt-12 text-center">
					<div className="text-h2">
						<div>
							Thanks for staying over! <br />
							Hope to see you next time ❤️
						</div>
					</div>
				</div>
			</div>
			<div className="mt-12 w-full">
				<Button
					type="button"
					onClick={() => resetReservations()}
					text="Close"
					suffix={<ArrowSmall />}
				/>
			</div>
		</div>
	);
}
