import {
  Routes,
  Route,
  useNavigate,
} from "react-router-dom"
import Home from "./pages/kiosk/Home"
import NoMatch from "./pages/NoMatch"
import "./assets/styles/globals.css"
import Layout from "./pages/kiosk/Layout"
import CheckIn from "./pages/kiosk/checkIn/CheckIn"
import SelectReservation from "./pages/kiosk/checkIn/SelectReservation"
import ReservationDetails from "./pages/kiosk/checkIn/ReservationDetails"
import CustomerForm from "./pages/kiosk/checkIn/CustomerForm"
import Consent from "./pages/kiosk/checkIn/Consent"
import OpenBillsCheckOut from "./pages/kiosk/checkOut/OpenBills"
import ScanWristband from "./pages/kiosk/checkIn/ScanWristband"
import ConfirmationCheckIn from "./pages/kiosk/checkIn/ConfirmationCheckIn"
import SelectResource from "./pages/kiosk/checkIn/SelectResource"
import SelectScene from "./pages/kiosk/checkIn/SelectScene"
import CheckOut from "./pages/kiosk/checkOut/CheckOut"
import Feedback from "./pages/kiosk/checkOut/Feedback"
import UpdateNewsletter from "./pages/kiosk/checkOut/UpdateNewsletter"
import ConfirmationCheckout from "./pages/kiosk/checkOut/ConfirmationCheckOut"
import OpenBillsAccount from "./pages/kiosk/checkIn/OpenBillsAccount"
import OpenBillsCustomer from "./pages/kiosk/checkIn/OpenBillsCustomer"
import CheckExpense from "./pages/kiosk/checkExpense/CheckExpense"
import OpenBillsExpense from "./pages/kiosk/checkExpense/OpenBillsExpense"
import ConfirmationExpense from "./pages/kiosk/checkExpense/ConfirmationExpense"
import { routeOut } from "./helpers/base"
import NoResourcesAvailable from "./pages/kiosk/checkIn/NoResourcesAvailable"
import ReservationCustomerForm from "./pages/kiosk/reservation/CustomerForm"
import ReservationScanWristband from "./pages/kiosk/reservation/ScanWristband"
import Index from "./pages/Index"
import SelectResourceVideo from "./pages/kiosk/checkIn/SelectResourceVideo"
import LayoutMapScreen from "./pages/mapScreen/LayoutMapScreen"
import HomeMapScreen from "./pages/mapScreen/HomeMapScreen"
import PreAuthExpense from "./pages/kiosk/checkExpense/PreAuthExpense"
import LayoutCityHostScreen from "./pages/cityHostScreen/LayoutCityHostScreen"
import HomeCityHostScreen from "./pages/cityHostScreen/HomeCityHostScreen"

function App() {
  routeOut.navigate = useNavigate()

  return (
    <Routes>
      <Route index element={<Index />} />

      <Route path="/kiosk/" element={<Layout />}>
        <Route index element={<Home />} />

        <Route path="check-expense/">
          <Route index element={<CheckExpense />} />
          <Route path="open-bills" element={<OpenBillsExpense />} />
          <Route path="pre-auth" element={<PreAuthExpense />} />
          <Route path="confirmation" element={<ConfirmationExpense />} />
        </Route>

        <Route path="check-in/">
          <Route index element={<CheckIn />} />
          <Route path="open-bills-account" element={<OpenBillsAccount />} />
          <Route path="select-reservation" element={<SelectReservation />} />

          <Route
            path="reservation-details/:id"
            element={<ReservationDetails />}
          />
          <Route path="open-bills-customer" element={<OpenBillsCustomer />} />
          <Route path="customer-form/:index" element={<CustomerForm />} />

          <Route path="select-resource" element={<SelectResource />} />
          <Route
            path="select-resource-video"
            element={<SelectResourceVideo />}
          />
          <Route path="select-scene" element={<SelectScene />} />
          <Route path="scan-wristband/:index" element={<ScanWristband />} />
          <Route path="confirmation" element={<ConfirmationCheckIn />} />
          <Route path="consent" element={<Consent />} />
          <Route
            path="no-resources-available"
            element={<NoResourcesAvailable />}
          />
        </Route>

        <Route path="check-out/">
          <Route index element={<CheckOut />} />
          <Route path="open-bills" element={<OpenBillsCheckOut />} />
          <Route path="feedback" element={<Feedback />} />
          <Route path="update-newsletter" element={<UpdateNewsletter />} />
          <Route path="confirmation" element={<ConfirmationCheckout />} />
        </Route>

        <Route path="reservation/">
          <Route
            path="customer-form/:index"
            element={<ReservationCustomerForm />}
          />
          <Route
            path="scan-wristband/:index"
            element={<ReservationScanWristband />}
          />
        </Route>
      </Route>

      <Route path="/mapscreen/" element={<LayoutMapScreen />}>
        <Route index element={<HomeMapScreen />} />
      </Route>

      <Route path="/cityhost-screen/" element={<LayoutCityHostScreen />}>
        <Route index element={<HomeCityHostScreen />} />
      </Route>

      <Route path="*" element={<NoMatch />} />
    </Routes>
  )
}

export default App
