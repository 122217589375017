import { useDispatch, useSelector } from "react-redux";
import { selectConfig } from "../../../features/config/configSlice";
import { InputField } from "../../../components/form/InputField";
import SubmitSuffix from "../../../components/button/SubmitSuffix";
import { FormEvent, useEffect, useState } from "react";
import { get } from "../../../helpers/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
	setActiveReservation,
	setOpenBills,
	setReservations,
} from "../../../features/reservation/reservationSlice";
import { showSnackbar } from "../../../helpers/notification";
import {
	clearLoadingOverlay,
	setLoadingOverlay,
} from "../../../features/application/appSlice";
import { IReservation } from "../../../features/reservation/reservationInterface";
import Button from "../../../components/button/Button";

export default function CheckIn() {
	const config = useSelector(selectConfig);
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	const [hasError, setHasError] = useState<boolean>(false);
	const [bigGroup, setBigGroup] = useState<boolean>(false);
	const [retry, setRetry] = useState<boolean>(false);
	const [searchString, setSearchString] = useState<string>("");
	const navigate = useNavigate();

	useEffect(() => {
		if (searchParams.has("reservationId")) {
			const reservationId = searchParams.get("reservationId");
			if (reservationId !== null && reservationId !== "") {
				setSearchString(reservationId);
				searchReservation(reservationId);
			}
		}
	}, []);

	const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		searchReservation();
	};

	const searchReservation = async (q: string = searchString) => {
		if (q.length) {
			dispatch(
				setLoadingOverlay({
					show: true,
					message: "Looking for your reservation",
					seconds: 10,
				}),
			);
			get({ url: `reservation/search`, params: { q: q } })
				.then((response) => {
					/**
					 * Reservation(s) found
					 */
					if (response.data && response.data.Success) {
						if (response.data.BigGroup) {
							setBigGroup(true);
							setHasError(true);
							dispatch(clearLoadingOverlay());
							return;
						}

						/**
						 * Check if we need a passport or id card
						 */
						if (
							response.data.Reservations.some((r: IReservation) => {
								return r.State === "identity-required";
							})
						) {
							setRetry(true);
							setHasError(true);
							dispatch(clearLoadingOverlay());
							return;
						}

						dispatch(setReservations(response.data.Reservations));
						dispatch(setOpenBills(response.data.OpenBills));

						/**
						 * Check if the reservations are already checked in
						 */
						if (
							response.data.Reservations.some((r: IReservation) => {
								return r.State === "started";
							})
						) {
							navigate("/kiosk/check-in/select-reservation");
							dispatch(clearLoadingOverlay());
							return;
						}

						/**
						 * Group reservation
						 * Check open bills. Pay before check in
						 * No open bills, navigate to select reservation
						 * */
						if (response.data.Reservations.length > 1) {
							if (response.data.OpenBills.Total.Value > 0.1) {
								navigate("/kiosk/check-in/open-bills-account");
							} else {
								navigate("/kiosk/check-in/select-reservation");
							}
							dispatch(clearLoadingOverlay());
						} else if (response.data.Reservations.length === 1) {
							/**
							 * Single reservation
							 * Check if there are resources available
							 */
							dispatch(
								setLoadingOverlay({
									show: true,
									message: `Checking if your ${response.data.Reservations[0].ResourceCategoryName} is ready.`,
									seconds: 10,
								}),
							);

							get({
								url: `resource/availability`,
								params: {
									reservationId: response.data.Reservations[0].ReservationId,
									refreshReservation: "false",
								},
							})
								.then((resourceResponse) => {
									/**
									 * Proceed if the resource is available
									 */
									if (resourceResponse.data && resourceResponse.data.Success) {
										dispatch(
											setActiveReservation(
												response.data.Reservations[0].ReservationId,
											),
										);
										navigate(
											"/kiosk/check-in/reservation-details/" +
												response.data.Reservations[0].ReservationId,
										);
									} else {
										/**
										 * No resources available, guest can use locker
										 */
										navigate("/kiosk/check-in/no-resources-available");
									}
									setTimeout(() => {
										dispatch(clearLoadingOverlay());
									}, 500);
								})
								.catch((error) => {
									showSnackbar(error.response?.Message, "error", "top-center");
									dispatch(clearLoadingOverlay());
								});
						}
					} else {
						/**
						 * Empty search string if it does not begin with ch
						 */
						if (q.toLowerCase().substring(0, 2) !== "ch") {
							setSearchString("");
						}
						showSnackbar(response.data.Message, "error", "bottom-center");
						dispatch(clearLoadingOverlay());
					}
				})
				.catch((error) => {
					showSnackbar(error.response?.Message, "error", "bottom-center");
					dispatch(clearLoadingOverlay());
				});
		} else {
			showSnackbar(
				"Please enter your booking number",
				"error",
				"bottom-center",
			);
		}
	};

	return (
		<div className="flex-grow flex flex-col justify-center items-center w-full">
			{!hasError && (
				<div className="grid grid-cols-1 gap-12 w-full">
					<div className="text-h1 text-center">Scan your QR code below</div>
					{config.Property?.VideoScanQrUrl && (
						<div className="flex justify-center w-full">
							<video autoPlay muted loop className="aspect-square h-auto">
								<source
									src={config.Property?.VideoScanQrUrl}
									type="video/mp4"
								/>
							</video>
						</div>
					)}
					<div>
						<div className="text-h3 text-center">Type your booking number</div>
						<div className="mt-4 w-3/4 m-auto">
							<form onSubmit={(e) => handleFormSubmit(e)}>
								<InputField
									type="text"
									name="searchString"
									placeholder="Your booking number"
									defaultValue={searchString}
									value={searchString}
									onChange={(e) => {
										setSearchString(e.target.value);
										showSnackbar("", "error", "bottom-center");
									}}
									role="presentation"
									autoComplete="off"
									autoFocus={true}
									aria-autocomplete="none"
									inputClasses="uppercase"
									suffix={<SubmitSuffix onSubmit={() => searchReservation()} />}
								/>
							</form>
						</div>
						<div id="spacer" className="h-96"></div>
					</div>
				</div>
			)}

			{bigGroup && (
				<>
					<div>
						<div className="text-h1 text-center">
							Oops!
							<br />
							You're with a group that is to big, please ask the host.
						</div>
					</div>
				</>
			)}

			{retry && (
				<>
					<div>
						<div className="text-h1 text-center">
							Oops!
							<br />
							We don’t have your ID/Passport in our system yet. Please find the
							CityHost to present your ID/Passport.
						</div>
					</div>
					<div className="mt-12 w-3/4">
						<form onSubmit={(e) => handleFormSubmit(e)}>
							<input
								type="hidden"
								name="searchString"
								defaultValue={searchString}
								value={searchString}
							/>
							<Button text={`Retry`} type="submit" />
						</form>
					</div>
				</>
			)}
		</div>
	);
}
