import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../../helpers/notification";
import {
	setActiveReservation,
	setReservations,
} from "../../../features/reservation/reservationSlice";
import ScanWristband from "../../../components/kiosk/ScanWristband";

export default function CheckOut() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	/**
	 * Get customer and reservation from ScanWristband
	 * Navigate to open bills, pre auth or directly to feedback
	 */
	const handleSubmit = (response: any) => {
		// showSnackbar(response.data.Message, "success", "top-center");
		dispatch(setReservations(response.data.Reservations));
		dispatch(setActiveReservation(response.data.Reservations[0].ReservationId));

		/**
		 * Check if the guest has open bills
		 */
		if (response.data.Reservations[0].OpenBills.Total.Value > 0) {
			/**
			 * Create pos payment
			 */
			navigate("/kiosk/check-out/open-bills");
		} else {
			navigate("/kiosk/check-out/feedback");
		}
	};

	return (
		<div className="flex-grow flex justify-center items-center flex-col w-full">
			<div className="grid grid-cols-1 gap-12">
				<div className="text-h1 text-center">
					Please scan your wristband here.
				</div>
				<ScanWristband
					endpoint={"/customer/token/get-check-out"}
					callBack={(token) => handleSubmit(token)}
				/>
			</div>
		</div>
	);
}
