import Button from "../../../components/button/Button";
import ArrowSmall from "../../../components/svg/ArrowSmall";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectActiveReservation } from "../../../features/reservation/reservationSlice";
import Payment from "../../../components/kiosk/Payment";
import { resetReservations } from "../../../helpers/reservation";

export default function OpenBillsExpense() {
	const activeReservation = useSelector(selectActiveReservation);
	const navigate = useNavigate();

	return (
		<>
			{activeReservation && activeReservation.OpenBills !== null && (
				<>
					<div className="flex-grow flex justify-center items-center flex-col w-full">
						{activeReservation.OpenBills.Total.Value > 0 && (
							<>
								<div className="text-h1 text-center">
									These have been your consumptions during your stay.
								</div>
								<div className="mt-12 w-full">
									<Payment
										openBills={activeReservation.OpenBills}
										minimalAmount={0}
										callBack={() => navigate("/kiosk/check-expense/open-bills")}
									/>
								</div>
							</>
						)}
						{activeReservation.OpenBills.Total.Value <= 0 && (
							<>
								<div className="text-h1 text-center">
									We haven't found any open bills.
								</div>
								<div className="mt-6 flex justify-between items-center w-full text-h3 text-center">
									<Button
										onClick={() => resetReservations()}
										type="button"
										text="Close"
										suffix={<ArrowSmall />}
									/>
								</div>
							</>
						)}
					</div>
				</>
			)}
		</>
	);
}
