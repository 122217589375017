import { useEffect, useState } from "react"
import Button from "../../../components/button/Button"
import ArrowSmall from "../../../components/svg/ArrowSmall"
import { useDispatch, useSelector } from "react-redux"
import {
  selectActiveReservation,
  selectReservation,
  setReservations,
} from "../../../features/reservation/reservationSlice"
import {
  clearLoadingOverlay,
  setLoadingOverlay,
} from "../../../features/application/appSlice"
import { post } from "../../../helpers/api"
import { showSnackbar } from "../../../helpers/notification"
import { resetReservations } from "../../../helpers/reservation"
import { selectConfig } from "../../../features/config/configSlice"

export default function ConfirmationCheckIn() {
  const dispatch = useDispatch()
  const config = useSelector(selectConfig)
  const reservation = useSelector(selectReservation)
  const activeReservation = useSelector(selectActiveReservation)
  const [success, setSuccess] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")

  useEffect(() => {
    handleSubmit()
  }, [])

  const handleSubmit = () => {
    if (activeReservation !== undefined) {
      dispatch(
        setLoadingOverlay({
          show: true,
          message: "We're checking you in...",
          seconds: 10,
        }),
      )
      post({
        url: `reservation/check-in`,
        params: {
          reservationId: activeReservation?.ReservationId,
          scene: reservation.LightScene,
        },
      })
        .then((response) => {
          if (response.data && response.data.Success) {
            setSuccess(true)
            showSnackbar(response.data.Message, "success", "top-center")
            dispatch(setReservations(response.data.Reservations))
            dispatch(clearLoadingOverlay())
          } else {
            setSuccess(false)
            setErrorMessage(response.data.Message)
            dispatch(clearLoadingOverlay())
          }
        })
        .catch((error) => {
          showSnackbar(error.response?.data.message, "error", "top-center")
          dispatch(clearLoadingOverlay())
        })
    }
  }

  return (
    <>
      <div className="flex-grow flex justify-center items-center flex-col w-full">
        {success && (
          <>
            <div className="text-h1 text-center">You're checked in</div>
            <div className="mt-12 text-center">
              <div className="text-h2">
                This is your {activeReservation?.ResourceCategoryName} number.
              </div>
              {config.Property?.Abbreviation === "CPH" && (
                <div className="text-h3">
                  You're in the {activeReservation?.AssignedFloorNumber} area.
                </div>
              )}
              <div className="m-24 text-big">
                {activeReservation?.AssignedResourceNumber}
              </div>
              <div className="text-h2">
                Plan your stay with <br />
                the CityHost!
              </div>
            </div>
          </>
        )}
        {success === false && errorMessage !== "" && (
          <>
            <div className="text-h1 text-center">You're NOT checked in</div>
            <div className="mt-12 text-center">
              <div className="text-h2">{errorMessage}</div>
            </div>
          </>
        )}

        <div className="mt-12 w-full flex flex-col space-y-6">
          <Button
            type="button"
            onClick={() => resetReservations()}
            text="Close"
            suffix={<ArrowSmall />}
          />
          {success === false && (
            <Button
              type="button"
              onClick={() => handleSubmit()}
              text="Try again"
              style="secondary"
              suffix={<ArrowSmall />}
            />
          )}
        </div>
      </div>
    </>
  )
}
