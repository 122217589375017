import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import {
  ICustomer,
  IOpenBills,
  IReservation,
  IReservationState,
} from "./reservationInterface"
import { findReservation, getIndex } from "../../helpers/reservation"
import { updateReservationState } from "./reservationApi"

const initialState: IReservationState = {
  ActiveReservation: "",
  IsGroup: false,
  Reservations: [],
  OpenBills: null,
  LightScene: 1,
}

// Update reservation state
export const _updateReservationState = createAsyncThunk(
  "reservation/updateReservationState",
  async (
    {
      reservationId,
      state,
    }: {
      reservationId: IReservation["ReservationId"]
      state: IReservation["State"]
    },
    { getState },
  ) => {
    const response = await updateReservationState(reservationId, state)
    return response.data
  },
)

export const reservationSlice = createSlice({
  name: "reservation",
  initialState,
  reducers: {
    setReservations: (state, action: PayloadAction<IReservation[]>) => {
      state.IsGroup = action.payload.length > 1
      state.Reservations = action.payload
    },
    setOpenBills: (state, action: PayloadAction<IOpenBills>) => {
      state.OpenBills = action.payload
    },
    setActiveReservation: (
      state,
      action: PayloadAction<IReservation["ReservationId"]>,
    ) => {
      state.ActiveReservation = action.payload
    },
    clearReservations: (state) => {
      state.ActiveReservation = ""
      state.Reservations = []
      state.OpenBills = null
      state.LightScene = 1
    },
    updateCustomer: (
      state,
      action: PayloadAction<{
        customer: ICustomer
        customerIndex: number
      }>,
    ) => {
      let reservations = [...state.Reservations]
      const reservationIndex = getIndex(reservations, state.ActiveReservation)
      reservations[reservationIndex].Customers[action.payload.customerIndex] =
        action.payload.customer
      state.Reservations = reservations
    },
    updateCustomerTokenId: (
      state,
      action: PayloadAction<{
        tokenId: ICustomer["TokenId"]
        customerIndex: number
      }>,
    ) => {
      let reservations = [...state.Reservations]
      const reservationIndex = getIndex(reservations, state.ActiveReservation)
      reservations[reservationIndex].Customers[action.payload.customerIndex][
        "TokenId"
      ] = action.payload.tokenId
      state.Reservations = reservations
    },
    setLightScene: (state, action: PayloadAction<IReservationState["LightScene"]>) => {
      state.LightScene = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(_updateReservationState.fulfilled, (state, action) => {
      console.log(action);
      
      state.Reservations = action.payload.Reservations
    })
  },
})

export const {
  setReservations,
  setOpenBills,
  setActiveReservation,
  clearReservations,
  updateCustomer,
  updateCustomerTokenId,
  setLightScene,
} = reservationSlice.actions

// Selectors
export const selectReservation = (state: RootState) => state.reservation
export const selectActiveReservation = (state: RootState) => {
  return findReservation(state.reservation.ActiveReservation);
}
export const selectReservations = (state: RootState) => state.reservation.Reservations
export const selectOpenBills = (state: RootState) => state.reservation.OpenBills

export default reservationSlice.reducer
